import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ZeusModules from "../components/zeusModules"
import MarqueeText from "../components/marqueeText.js"
import HeadingCta from "../components/headingCta"
import styles from "./live-scoping.module.scss"

import Compass from "../images/compass.svg"

export const query = graphql`
  query {
    craft {
      page: entry(section: "liveScoping") {
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_liveScoping_liveScoping_Entry {
          alternateTitle
          body
          zeusModules {
            ...ZeusModules
          }
          headingCta {
            ...HeadingCta
          }
        }
      }
    }
  }
`

const LiveScopingPage = ({ data }) => {
  const page = data.craft.page
  const seo = page.seomatic
  let title = page.alternateTitle != null ? page.alternateTitle : page.title

  return (
    <Layout seo={seo}>
      <div className="page-content-module">
        <div className={styles.title}>
          <div className="wrapper">
            <div className="text-center">
              <h1
                className="h-1 h--cms-title mb-4"
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              ></h1>
            </div>
          </div>
        </div>
        <div className={styles.compass}>
          <Compass />
        </div>
        <div className={styles.marquee}>
          <MarqueeText rate={3}>
            <span className={styles.marqueeText}>
              <span className={styles.heading}>
                Business is moving faster than ever.
              </span>
            </span>
          </MarqueeText>
        </div>
        <div className={styles.body}>
          <div className="wrapper">
            <div className="columns is-tablet is-centered">
              <div className="column is-8-tablet is-6-widescreen">
                <div
                  className="user-content body-large-after-tablet text-center"
                  dangerouslySetInnerHTML={{
                    __html: page.body,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <ZeusModules data={page.zeusModules} />
        <HeadingCta data={page.headingCta[0]} />
      </div>
    </Layout>
  )
}

export default LiveScopingPage
